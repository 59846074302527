<template>
    <div>
        <p class="mb-0" v-if="!isExpanded">{{ shortText }}</p>
        <p class="mb-0" v-else>{{ text }}</p>
        <a style="cursor: pointer" v-on:click="toggleText">
            {{ isExpanded ? 'Read less' : 'Read more' }}
        </a>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },
        maxChars: {
            type: Number,
            default: 100
        }
    },
    data() {
        return {
            isExpanded: false
        };
    },
    methods: {
        toggleText() {
            this.isExpanded = !this.isExpanded;
        }
    },
    computed: {
        shortText() {
            return this.text.substr(0, this.maxChars);
        }
    }
}
</script>
